(function(w, d) {

  var iframePlayer = {};
  var $iframeNode = null;

  iframePlayer.init = function() {
    iframePlayer.initIframeNode();
  };
  
  iframePlayer.initIframeNode = function() {
    $iframeNode = $('.js-iframe');
  };

  iframePlayer.destroy = function() {
    $iframeNode.removeAttr('src');
  };

  iframePlayer.setSrc = function(src) {
    $iframeNode.attr('src', src);
  };

  iframePlayer.getSrc = function() {
    return $iframeNode.attr('src');
  };

  w.iframePlayer = iframePlayer;
})(window, document);
